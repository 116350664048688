import { toRefs, Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import * as api from '@fwk-client/utils/api';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

interface ModuleAdminRightsInput {
  userID:string
}

const modules = ["CMS", "SHOP", "BLOG", "VIGNERONONLINE", "HOSPITALITY", "AMAZON", "VAT_VALIDATION"];

export function useModuleAdminRights(props:ModuleAdminRightsInput, {emit}:any) { 
  const app = getApp();

  const { userID } = toRefs(props);

  var isListLoading:Ref<boolean> = ref(false);
  var userModuleAdminRights:Ref<any[]> = ref([]);

  const callModuleAdminRightsAdmin = async (path:string, input?:any, formData?:FormData) => {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: app
    }
    if(!input) { input = {}; }
    if(formData) {
      return api.postAPIFormData('/api/admin/module-admin-rights' + path, formData, options, input)
    }
    return api.postAPI('/api/admin/module-admin-rights' + path, input, options);
  }

  const updateListModuleAdminRights = (userID:string) => {
    // We need to get the list of available companies for the current logged in user
    isListLoading.value = true;
    callModuleAdminRightsAdmin('/user/'+userID).then((response:any) => {
      if(response.moduleAdminRights) {  
        userModuleAdminRights.value = response.moduleAdminRights;
      }
      else {
        userModuleAdminRights.value = [];
      }
      isListLoading.value = false;
    });
  }
  updateListModuleAdminRights(userID.value);

  const userModuleAdminRightsForm:any = reactive({
    targetID:'',
    module:'',
    authRoles:[]
  })

  const updateUserModuleAdminRightsFormForUpdate = (moduleAdminRight:any) => {
    userModuleAdminRightsForm.moduleAdminRightID = (moduleAdminRight && moduleAdminRight._id) ? moduleAdminRight._id : undefined;
    userModuleAdminRightsForm.targetID = (moduleAdminRight && moduleAdminRight.targetID) ? moduleAdminRight.targetID : '';
    userModuleAdminRightsForm.module = (moduleAdminRight && moduleAdminRight.module) ? moduleAdminRight.module : '';
    userModuleAdminRightsForm.authRoles = (moduleAdminRight && moduleAdminRight.authRoles) ? moduleAdminRight.authRoles : [];
  }

  const updateUserModuleAdminRights = async () => {

    var result:any = {
      updated: false
    }

    var input = {
      ...userModuleAdminRightsForm
    }

    isListLoading.value = true;

    try {
      var response = await callModuleAdminRightsAdmin('/user/'+userID.value+'/update', input);
      if(response.updated) {  
        // We update the form with updated admin rights
        updateUserModuleAdminRightsFormForUpdate(response.moduleAdminRight);

        // We update the list of shops
        updateListModuleAdminRights(userID.value);

        result.updated = true;
        result.moduleAdminRight = response.moduleAdminRight;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  const removeUserModuleAdminRights = async (userAdminRightsID:string) => {

    isListLoading.value = true;

    try {
      var response = await callModuleAdminRightsAdmin('/'+userAdminRightsID+'/remove');
      if(response.removed) {
        updateListModuleAdminRights(userID.value);
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return true;
  }

  // We watch if we have site ID in URL
  watch(
    userID,
    (val:any, oldVal:any) => {
      updateListModuleAdminRights(userID.value);       
    },
    { deep: false }
  )

  return {
    userModuleAdminRights,
    isListLoading,
    updateListModuleAdminRights,
    updateUserModuleAdminRights,
    removeUserModuleAdminRights,
    userModuleAdminRightsForm,
    updateUserModuleAdminRightsFormForUpdate,
    availableModules:modules
  }
  
}