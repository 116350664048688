<template>
  <section id="content">
    <PageTitle title="Administration"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-title">
              <h2>List of users</h2>
            </div>
            <div :class="'ibox-content p-md' + (listUsersLoading ? ' sk-loading' : '')">

              <div v-if="listUsersLoading" class="sk-spinner sk-spinner-double-bounce">
                  <div class="sk-double-bounce1"></div>
                  <div class="sk-double-bounce2"></div>
              </div>

              <b-table 
                  outlined striped
                  :items="usersProvider"
                  :fields="userFields"
                  ref="listUsers">
                <template v-slot:cell(select)="row">
                  <p-check :id="'updateDetailsIsActive_'+row.item._id" class="p-default p-curve p-primary" name="listUsersSelected" color="success" :value="row.item._id" v-model="listUsersSelected"></p-check>
                </template>
                <template v-slot:cell(isLoggedIn)="row">
                  <i v-if="row.item.isLoggedIn" :class="['fa','fa-user-circle-o','active']"></i>
                </template>
                <template v-slot:cell(isActive)="row">
                  <i :class="['fa','fa-check',row.item.isActive ? 'active' : 'disabled']"></i>
                </template>
                <template v-slot:cell(isTotp)="row">
                  <i :class="['fa','fa-check',row.item.hasSecretKey ? 'active' : 'disabled']"></i>
                </template>
                <template v-slot:cell(options)="row">
                  <a href="javascript:void(0)" @click="showUpdateUserModal(row.item)">Update</a>
                  /
                  <a href="javascript:void(0)" @click="showUserModulesModal(row.item)">Modules</a>
                  /
                  <a href="javascript:void(0)" @click="showUpdateProfileModal(row.item)">Profile</a>
                  /
                  <a href="javascript:void(0)" @click="confirmRemoveUser(row.item)">Delete</a>
                </template>
              </b-table>

              <div v-if="listUsersSelected.length > 0">
                <button class="btn btn-primary" @click="exportSelectedUsers()">Export</button>
              </div>
              <button class="btn btn-primary" @click="showCreateUserModal()">Create user</button>
              <button class="btn btn-primary" @click="cleanupAuthTokens()">Cleanup auth tokens</button>
              <b-form-file
                v-model="listUsersToImport"
                :state="Boolean(listUsersToImport)"
                placeholder="Import users"
                drop-placeholder="Import users"
                @input="onListUsersToImportChange()"
              />

              <b-modal size="lg" ref="updateUserModal" :title="'User details ('+ userToUpdate.login +')'" hide-footer lazy>
                <UpdateUser :user="userToUpdate" v-on:user-updated="onUserUpdated" />
              </b-modal>

              <b-modal size="lg" ref="userModulesModal" :title="'User modules ('+ userToUpdate.login +')'" hide-footer lazy>
                <UserModules :user="userToUpdate" v-on:user-updated="onUserUpdated" />
              </b-modal>

              <b-modal size="lg" ref="updateProfileModal" :title="'Profile details ('+ userToUpdate.login +')'" hide-footer lazy>
                <UpdateProfile :user="userToUpdate" v-on:profile-updated="onProfileUpdated" />
              </b-modal>

              <b-modal size="lg" ref="createUserModal" title="Create new user" hide-footer lazy>
                <CreateUser v-on:user-created="onUserCreated" />
              </b-modal>

              <b-modal ref="removeUserModal" 
                  hide-header
                  @ok="removeUser">
                Do you want to remove user: {{ userToRemove.login }} - {{ userToRemove._id }} ?
              </b-modal>

            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-title">
              <h2>List of roles</h2>
            </div>
            <div :class="'ibox-content p-md' + (listRolesLoading ? ' sk-loading' : '')">

              <div v-if="listRolesLoading" class="sk-spinner sk-spinner-double-bounce">
                  <div class="sk-double-bounce1"></div>
                  <div class="sk-double-bounce2"></div>
              </div>

              <b-table 
                  outlined striped
                  :items="roleProvider"
                  :fields="roleFields"
                  ref="listRoles">
                <template v-slot:cell(options)="row">
                  <a href="javascript:void(0)" @click="showUpdateRoleModal(row.item)">Update</a>
                  /
                  <a href="javascript:void(0)" @click="confirmRemoveRole(row.item)">Delete</a>
                </template>
              </b-table>

              <button class="btn btn-primary" @click="showCreateRoleModal()">Add role</button>

              <b-modal size="lg" ref="updateRoleModal" :title="'List of modules for'+ roleToUpdate.role +')'" hide-footer lazy>
                <UpdateRole :role="roleToUpdate" v-on:role-updated="onRoleUpdated" />
              </b-modal>

              <b-modal size="lg" ref="createRoleModal" title="Add new role" hide-footer lazy>
                <CreateRole v-on:role-created="onRoleCreated" />
              </b-modal>

              <b-modal ref="removeRoleModal" 
                  hide-header
                  @ok="removeRole">
                Do you want to remove role: {{ roleToRemove.role }} ?
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  td div.pretty {
    margin:0;
  }
</style>



<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import * as api from '@fwk-client/utils/api';
import UpdateUser from '../../panels/admin/users/Update.vue';
import CreateUser from '../../panels/admin/users/Create.vue';
import UserModules from '../../panels/admin/users/Modules.vue';
import UpdateProfile from '../../panels/admin/profile/Update.vue';
import UpdateRole from '../../panels/admin/roles/Update.vue';
import CreateRole from '../../panels/admin/roles/Create.vue';
import PageTitle from '../../panels/PageTitle.vue';
import { formatDate, formatDay } from '@igotweb-node-api-utils/formatter';
import { languagesTypes } from '@fwk-client/store/types';

@Component({
  components: { 
    UpdateUser,
    UpdateProfile,
    CreateUser,
    UpdateRole,
    CreateRole,
    PageTitle,
    UserModules
  }
})
export default class Users extends mixins(GenericPage) {

  listRolesLoading = false;
  listUsersLoading = false;

  readonly emptyUser = {
    "login" : ""
  };

  userToUpdate:any = {...this.emptyUser};
  userToRemove: any = {...this.emptyUser};

  listUsersSelected = [];
  listUsersToImport = null;

  userFields = [
      {
        key: "select",
        label: ""
      },
      {
        key: "isLoggedIn",
        label: "Online"
      },
      {
        key: "login"
      },
      {
        key: "email"
      },
      {
        key: "lastConnectionDateTime",
        label: "Last Connection",
        formatter: (value:any) => {
          if(!value) { return ""; }
          return formatDate(value, this.currentLanguageCode);
        }
      },
      {
        key: "roles"
      },
      {
        key: "isActive",
        label: "Active"
      },
      {
        key: "isTotp",
        label: "2FA"
      },
      {
        key: "options"
      }
    ];

  get currentLanguageCode() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }

  usersProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listUsersLoading = true;

    return api.postAPI('/api/admin/users/list', {}, options).then((response:any) => {
      this.listUsersLoading = false;
      if(response.users) {  
        return response.users;
      }
      else {
        return [];
      }
    })
  }

  onUserCreated() {
    // @ts-ignore
    this.$refs.createUserModal.hide()
    // @ts-ignore
    this.$refs.listUsers.refresh()
  }

  onUserUpdated(user:any) {
    // We update the user
    this.userToUpdate = user;
    // @ts-ignore
    this.$refs.listUsers.refresh()
  }

  onProfileUpdated() {
    // @ts-ignore
    this.$refs.listUsers.refresh()
  }

  onUserRemoved() {
    // @ts-ignore
    this.$refs.listUsers.refresh()
  }

  confirmRemoveUser(user:any) {
    this.userToRemove = user;
    // @ts-ignore
    this.$refs.removeUserModal.show()
  }

  removeUser() {

    var input = {
      "userID" : this.userToRemove._id
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listUsersLoading = true;
    
    api.postAPI('/api/admin/users/remove', input, options).then((response:any) => {
      
      this.listUsersLoading = false;

      if(response.removed) {
        // We update the list of users
        this.onUserRemoved();
      }
      // We reset the user to remove
      this.userToRemove = {...this.emptyUser};
    });
  }

  showUpdateUserModal(user:any) {
    this.userToUpdate = user;
    // @ts-ignore
    this.$refs.updateUserModal.show()
  }

  showUserModulesModal(user:any) {
    this.userToUpdate = user;
    // @ts-ignore
    this.$refs.userModulesModal.show()
  }

  showUpdateProfileModal(user:any) {
    this.userToUpdate = user;
    // @ts-ignore
    this.$refs.updateProfileModal.show()
  }

  showCreateUserModal() {
    // @ts-ignore
    this.$refs.createUserModal.show()
  }

  exportSelectedUsers() {
    var input = {
      userIds : this.listUsersSelected
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    return api.postAPI('/api/admin/users/export', input, options).then((response:any) => {
      
    })
  }

  cleanupAuthTokens() {
    var input = {}

    var options:api.ApiVueOptions =  {
      app: this
    }

    return api.postAPI('/api/admin/users/cleanup-auth-tokens', input, options).then((response:any) => {
      
    })
  }

  onListUsersToImportChange(evt:Event) {
    console.log(evt);
    console.log(this.listUsersToImport);
  }


  readonly emptyRole = {
    "role" : ""
  };

  roleToUpdate:any = {...this.emptyRole};
  roleToRemove: any = {...this.emptyRole};

  roleFields = [
      {
        key: "role"
      },
      {
        key: "modules"
      },
      {
        key: "options"
      }
    ];

  roleProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    return api.getAPI('/api/admin/roles/list', options).then((response:any) => {
      if(response.roles) {  
        return response.roles;
      }
      else {
        return [];
      }
    })
  }

  onRoleCreated() {
    // @ts-ignore
    this.$refs.createRoleModal.hide()
    // @ts-ignore
    this.$refs.listRoles.refresh()
  }

  onRoleUpdated() {
    // @ts-ignore
    this.$refs.listRoles.refresh()
  }

  onRoleRemoved() {
    // @ts-ignore
    this.$refs.listRoles.refresh()
  }

  confirmRemoveRole(role:any) {
    this.roleToRemove = role;
    // @ts-ignore
    this.$refs.removeRoleModal.show()
  }

  removeRole() {

    var input = {
      "roleID" : this.roleToRemove._id
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listRolesLoading = true;
    
    api.postAPI('/api/admin/roles/remove', input, options).then((response:any) => {
      if(response.removed) {
        // We update the list of roles
        this.onRoleRemoved();
      }
      // We reset the role to remove
      this.roleToRemove = {...this.emptyRole};

      this.listRolesLoading = false;
    });
  }

  showUpdateRoleModal(role:any) {
    this.roleToUpdate = role;
    // @ts-ignore
    this.$refs.updateRoleModal.show()
  }

  showCreateRoleModal() {
    // @ts-ignore
    this.$refs.createRoleModal.show()
  }
  
}
</script>