<template>
  
    <form role="form" @submit="onFormSubmit" name="create-user">
        <div class="form-group">
            <label for="createLogin" class="sr-only">Login</label>
            <input id="createLogin" type="text" placeholder="Login" class="form-control" v-model="form.login" required />
        </div>
        <div class="form-group">
            <label for="createEmail" class="sr-only">EMail</label>
            <input id="createEmail" type="email" placeholder="EMail" class="form-control" v-model="form.email" required />
        </div>
        <div class="form-group">
            <label for="createPassword" class="sr-only">Password</label>
            <input id="createPassword" type="password" placeholder="Password" autocomplete="new-password" class="form-control" v-model="form.password" />
        </div>
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Create</button>
    </form>

</template>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: { }
})
export default class Create extends Vue {

  form = {
    login: '',
    email: '',
    password: ''
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-user] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "login" : this.form.login,
      "email" : this.form.email,
      "password" : this.form.password
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/admin/users/create', input, options).then((response:any) => {
      if(response.created) {  
        this.$emit('user-created', response.user);

        // We reset the field
        this.form.login = "";
        this.form.email = "";
        this.form.password = "";
      }
      this.laddaSubmit!.stop();
    });
  }
  
}
</script>