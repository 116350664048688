<template>
  
    <form role="form" @submit="onFormSubmit" name="update-profile">
        <div class="form-group row">
          <label for="updateCivility" class="col-lg-3 col-form-label">{{ $t('profile.info.civility') }}</label>
          <div class="col-lg-9">
            <select id="updateCivility" class="form-control" v-model="form.civility">
              <option value="">Select</option>
              <option v-for="(civility, index) in civilities" :key="index" :value="civility">{{$t('civilities.long.'+civility)}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
            <label for="updateFirstName" class="col-lg-3 col-form-label">{{ $t('profile.info.firstName') }}</label>
            <div class="col-lg-9">
              <input id="updateFirstName" type="text" :placeholder="$t('profile.info.firstName_placeholder')" class="form-control" v-model="form.firstName" />
            </div>
        </div>
        <div class="form-group row">
            <label for="updateMiddleName" class="col-lg-3 col-form-label">{{ $t('profile.info.middleName') }}</label>
            <div class="col-lg-9">
              <input id="updateMiddleName" type="text" :placeholder="$t('profile.info.middleName_placeholder')" class="form-control" v-model="form.middleName" />
            </div>
        </div>
        <div class="form-group row">
            <label for="updateLastName" class="col-lg-3 col-form-label">{{ $t('profile.info.lastName') }}</label>
            <div class="col-lg-9">
              <input id="updateLastName" type="text" :placeholder="$t('profile.info.lastName_placeholder')" class="form-control" v-model="form.lastName" />
            </div>
        </div>
        <div class="form-group row">
            <label for="updateCompany" class="col-lg-3 col-form-label">{{ $t('profile.info.company') }}</label>
            <div class="col-lg-9">
              <div v-if="listCompanies.length == 0" class="sk-spinner sk-spinner sk-spinner-three-bounce">
                  <div class="sk-bounce1"></div>
                  <div class="sk-bounce2"></div>
                  <div class="sk-bounce3"></div>
              </div>
              <v-select v-if="listCompanies.length > 0" id="updateCompany" :placeholder="$t('profile.info.company_placeholder')" v-model="form.company" :options="listCompanies" label="name"></v-select>
            </div>
        </div>
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Update profile</button>
    </form>

</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import $ from 'jquery';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: { }
})
export default class Update extends Vue {

  @Prop({
    type: Object,
    required: false
  }) readonly user!: any | undefined

  civilities = ["MR","MRS"];

  profile = this.user.profile;

  form = {
    civility: this.profile.civility ? this.profile.civility : "",
    firstName : this.profile.firstName,
    middleName : this.profile.middleName,
    lastName : this.profile.lastName,
    company : this.profile.company
  };

  laddaSubmit:Ladda.LaddaButton|null = null;
  listCompanies:any[] = [];

  created() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/admin/companies/list', options).then((response:any) => {
      if(response.companies) {  
        this.listCompanies = response.companies;
      }
    });
  }

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=update-profile] button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "userID" : this.user._id,
      "civility" : this.form.civility,
      "firstName" : this.form.firstName,
      "middleName" : this.form.middleName,
      "lastName" : this.form.lastName,
      "companyID" : this.form.company ? this.form.company._id : undefined,
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/admin/users/profile/update', input, options).then((response:any) => {
      if(response.updated) {
        // We emit event as profile is updated
        this.$emit('profile-updated', response.user); 
      }
      this.laddaSubmit!.stop();
    });
  }
  
}
</script>