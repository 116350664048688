<template>
  
    <form role="form" @submit="onFormSubmit" name="module-form">
      <Select
        label="Module"
        placeholder="Select a module"
        :selectOptions="{
          options: availableModules
        }"
        v-bind:value.sync="userModuleAdminRightsForm.module" 
        :labelAsColumn="true"
        :required="true"
      />
      <Select
        label="Target"
        placeholder="Select a target"
        :selectOptions="{
          options: listTargets,
          getOptionLabel : option => option.name,
          reduce: option => option.targetID
        }"
        v-bind:value.sync="userModuleAdminRightsForm.targetID" 
        :labelAsColumn="true"
        :required="true"
      />
      <Select
        label="Roles"
        placeholder="Add roles"
        :selectOptions="{
          multiple: true,
          options: availableTargetRoles
        }"
        v-bind:value.sync="userModuleAdminRightsForm.authRoles" 
        :labelAsColumn="true"
      />
      <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{buttonLabel}}</button>
    </form>

</template>


<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import ListTextFields from '@fwk-client/components/panels/input/ListTextFields.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { useSiteAdmin } from '../../../../modules/cms/composables/useSiteAdmin';
import { useShopAdmin } from '../../../../modules/shop/composables/useShopAdmin'
import { useVigneronOnlineAdmin } from '../../../../modules/vigneron-online/composables/useVigneronOnlineAdmin';
import { useFeedAdmin } from '../../../../modules/blog/composables/useFeedAdmin';
import { useModuleAdmin as useAmazonAdmin} from '../../../../modules/amazon/composables/useModuleAdmin';
import { useModuleAdmin as useVatValidationAdmin} from '../../../../modules/vat-validation/composables/useModuleAdmin';

import { useModuleAdminRights } from '../../../../composables/admin/useModuleAdminRights';
import { useAgencyAdmin } from '@root/src/client/modules/hospitality/composables/useAgencyAdmin';

export default defineComponent({
  props: {
      user: {
        type: Object as PropType<any>,
        required: true
      },
      moduleAdminRight: {
        type: Object as PropType<any>,
        required: false
      }
  },
  components: {
      SwitchCheck, TextField, ListTextFields, Select
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { feeds, blogRoles } = useFeedAdmin(props, context);
    const { shopContents, shopContentRoles } = useVigneronOnlineAdmin(props, context);
    const { sites, cmsRoles } = useSiteAdmin(props, context);
    const { shops, shopRoles } = useShopAdmin(props, context);
    const { agencies, hospitalityRoles } = useAgencyAdmin(props, context);
    const { modules:amazonModules, moduleRoles:amazonRoles } = useAmazonAdmin(props, context);
    const { modules:vatValidationModules, moduleRoles:vatValidationRoles } = useVatValidationAdmin(props, context);

    const { updateUserModuleAdminRightsFormForUpdate, updateUserModuleAdminRights, userModuleAdminRightsForm, availableModules } = useModuleAdminRights({
      userID:props.user._id,
      ...props}, context);

    const { moduleAdminRight } = toRefs(props);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    const buttonLabel = computed(() => {
      if(moduleAdminRight.value) { return app.$t('cms.site.update.button') }
      else { return app.$t('cms.site.create.button'); }
    })

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=module-form] button' );
      laddaSubmit = Ladda.create(button!);
    })

    updateUserModuleAdminRightsFormForUpdate(moduleAdminRight.value);

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      updateUserModuleAdminRights().then((result:any) => {
        if(result.updated) {
          context.emit('updated', result.moduleAdminRight);
        }
        laddaSubmit!.stop();
      })
    }

    const listTargets:Ref<any[]> = ref([]);
    const targetRoles:Ref<string[]> = ref([])

    const updateListTargetsFromModule = (module:string) => {
      switch(module) {
          case 'CMS':
            listTargets.value = sites.value.map((site:any) => {
              return {
                name: site.site.name,
                targetID: site.site._id
              }
            })
            targetRoles.value = Object.values(cmsRoles);
          break;
          case 'VIGNERONONLINE':
            listTargets.value = shopContents.value.map((shopContent:any) => {
              return {
                name: shopContent.shopContent.shop.name,
                targetID: shopContent.shopContent._id
              }
            })
            targetRoles.value = Object.values(shopContentRoles);
          break;
          case 'SHOP':
            listTargets.value = shops.value.map((shop:any) => {
              return {
                name: shop.shop.name,
                targetID: shop.shop._id
              }
            })
            targetRoles.value = Object.values(shopRoles);
          break;
          case 'BLOG':
            listTargets.value = feeds.value.map((feed:any) => {
              return {
                name: feed.feed.name,
                targetID: feed.feed._id
              }
            })
            targetRoles.value = Object.values(blogRoles);
          break;
          case 'HOSPITALITY':
            console.log("updateListTargetsFromModule");
            console.log(agencies.value);
            listTargets.value = agencies.value.map((agency:any) => {
              return {
                name: agency.agency.name,
                targetID: agency.agency._id
              }
            })
            targetRoles.value = Object.values(hospitalityRoles);
          break;
          case 'AMAZON':
            console.log("updateListTargetsFromModule");
            console.log(amazonModules.value);
            listTargets.value = amazonModules.value.map((module:any) => {
              return {
                name: module.module.name,
                targetID: module.module._id
              }
            })
            targetRoles.value = Object.values(amazonRoles);
          break;
          case 'VAT_VALIDATION':
            console.log("updateListTargetsFromModule");
            console.log(vatValidationModules.value);
            listTargets.value = vatValidationModules.value.map((module:any) => {
              return {
                name: module.module.name,
                targetID: module.module._id
              }
            })
            targetRoles.value = Object.values(vatValidationRoles);
          break;
          default:
            listTargets.value = [];
            targetRoles.value = [];
        }
    }

    const availableTargetRoles = computed(() => {
      return targetRoles.value.filter((role:any) => {
        if(!userModuleAdminRightsForm.authRoles) { return true; }
        else {
          return userModuleAdminRightsForm.authRoles.indexOf(role) < 0
        }
      })
    });

    if(moduleAdminRight.value) {
      updateListTargetsFromModule(moduleAdminRight.value.module);
    }

    watch(
      () => userModuleAdminRightsForm.module,
      (val:any, oldVal:any) => {
        // We reset the form
        userModuleAdminRightsForm.targetID = '';
        userModuleAdminRightsForm.authRoles = [];
        // We update the list
        updateListTargetsFromModule(val);
      },
      { deep: false }
    )

    

    return {
      onFormSubmit,
      userModuleAdminRightsForm,
      buttonLabel,
      availableModules,
      listTargets,
      availableTargetRoles
    }
  }
})
</script>