<template>
  
    <form role="form" @submit="onFormSubmit" name="update-user">
        <div class="form-group row">
            <label for="updateDetailsLogin" class="col-lg-3 col-form-label">Login</label>
            <div class="col-lg-9">
              <input id="updateDetailsLogin" type="text" placeholder="Login" class="form-control" v-model="form.login">
            </div>
        </div>
        <div class="form-group row">
            <label for="updateDetailsEmail" class="col-lg-3 col-form-label">EMail</label>
            <div class="col-lg-9">
              <input id="updateDetailsEmail" type="email" placeholder="EMail" class="form-control" v-model="form.email">
            </div>
        </div>
        <div class="form-group row">
            <label for="updateDetailsRoles" class="col-lg-3 col-form-label">Roles</label>
            <div class="col-lg-9">
              <div v-if="availableRoles.length == 0" class="sk-spinner sk-spinner sk-spinner-three-bounce">
                  <div class="sk-bounce1"></div>
                  <div class="sk-bounce2"></div>
                  <div class="sk-bounce3"></div>
              </div>
              <v-select v-if="availableRoles.length > 0" id="updateDetailsRoles" placeholder="Roles" multiple v-model="form.roles" :options="availableRoles"></v-select>
            </div>
        </div>
        <div class="form-group row">
            <label for="updateDetailsIsActive" class="col-lg-3 col-form-label">Is active</label>
            <div class="col-lg-9">
              <p-check id="updateDetailsIsActive" class="p-switch p-fill" color="success" v-model="form.isActive">&nbsp;</p-check>
            </div>
        </div>
        <div class="form-group row">
            <label for="updateDetailsIsTotp" class="col-lg-3 col-form-label">Is 2FA Required</label>
            <div class="col-lg-9">
              <p-check id="updateDetailsIsTotp" class="p-switch p-fill" color="success" v-model="form.isTotp">&nbsp;</p-check>
              <button class="btn btn-secondary ladda-button reset-totp" data-style="zoom-in" type="button" :disabled="!form.isTotp" @click="resetTotp">Reset secret code</button>
            </div>
        </div>
        <div class="form-group row">
            <label for="updateDetailsPassword" class="col-lg-3 col-form-label">Password</label>
            <div class="col-lg-9">
              <input id="updateDetailsPassword" type="password" placeholder="Password" autocomplete="new-password" class="form-control" v-model="form.password">
            </div>
        </div>
        <button class="btn btn-primary ladda-button update-details" data-style="zoom-in" type="submit">Update details</button>
    </form>

</template>

<style scoped>
  .sk-spinner {
    height: 33px;
    padding-top: 7px;
    text-align: left;
    margin: 0;
  }
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components: { }
})
export default class Update extends Vue {

  @Prop({
    type: Object,
    required: false
  }) readonly user!: any | undefined

  form = {
    login: this.user.login,
    email: this.user.email,
    roles : this.user.roles, // We put comma + space as separator between roles.
    password : '',
    isActive : this.user.isActive,
    isTotp : this.user.hasSecretKey
  };

  laddaSubmit:Ladda.LaddaButton|null = null;
  laddaResetTotp:Ladda.LaddaButton|null = null;

  listRoles:string[] = [];

  created() {
    // We need to get the list of available roles for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/admin/roles/available-roles', options).then((response:any) => {
      if(response.roles) {  
        this.listRoles = response.roles;
      }
    });
  }

  get availableRoles() {
    return this.listRoles.filter((role:any) => {
      if(!this.form.roles) { return true; }
      else {
        return this.form.roles.indexOf(role) < 0
      }
    })
  };

  mounted() {
    var updateDetailsButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-user] button.ladda-button.update-details' );
    this.laddaSubmit = Ladda.create(updateDetailsButton!);

    var resetTotpButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-user] button.ladda-button.reset-totp' );
    this.laddaResetTotp = Ladda.create(resetTotpButton!);
  }

  resetTotp(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "userID" : this.user._id
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaResetTotp!.start();
    
    api.postAPI('/api/admin/users/reset-totp', input, options).then((response:any) => {
      if(response.reseted) {  
        var user = response.user;
        // We emit event as user is updated
        this.$emit('user-updated', user);
      }

      this.laddaResetTotp!.stop();
    });
    
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "userID" : this.user._id,
      "login" : this.form.login,
      "email" : this.form.email,
      "roles" : this.form.roles,
      "password" : this.form.password,
      "isActive" : this.form.isActive,
      "isTotp" : this.form.isTotp
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/admin/users/update', input, options).then((response:any) => {
      if(response.updated) {  
        var user = response.user;
        // We emit event as user is updated
        this.$emit('user-updated', user);

        // We update the form based on values returned by backend (in case not updated)
        this.form.login = user.login;
        this.form.email = user.email;
      }
      // We reset the field
      this.form.password = "";

      this.laddaSubmit!.stop();
    });
  }
  
}
</script>