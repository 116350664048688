<template>
  <div class="ibox user-admin-rights">
    <div class="ibox-title">
      <h2>User Admin Rights</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="userModuleAdminRights"
          :fields="listFields"
          ref="listItems">
        <template v-slot:cell(options)="row">
          <a href="javascript:void(0)" @click="showUpdateModal(row.item)">Update</a>
          /
          <a href="javascript:void(0)" @click="confirmRemoveItem(row.item)">Delete</a>
        </template>
      </b-table>

      <button class="btn btn-primary" @click="showCreateItemModal()">Create</button>

      <b-modal size="lg" ref="updateItemModal" title="Update" hide-footer lazy>
        <ModuleForm :moduleAdminRight="itemToUpdate" :user="user" v-on:updated="onItemUpdated" />
      </b-modal>

      <b-modal size="lg" ref="createItemModal" title="Create" hide-footer lazy>
        <ModuleForm :user="user" v-on:updated="onItemCreated" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        Remove ?
      </b-modal>
    </div>
  </div>

</template>

<style scoped>
  .sk-spinner {
    height: 33px;
    padding-top: 7px;
    text-align: left;
    margin: 0;
  }
</style>


<script lang="ts">
import { toRefs, ComputedRef, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { languagesTypes } from '@fwk-client/store/types';
import ModuleForm from './ModuleForm.vue';

import { useSiteAdmin } from '../../../../modules/cms/composables/useSiteAdmin';
import { useShopAdmin } from '../../../../modules/shop/composables/useShopAdmin'
import { useVigneronOnlineAdmin } from '../../../../modules/vigneron-online/composables/useVigneronOnlineAdmin';
import { useFeedAdmin } from '../../../../modules/blog/composables/useFeedAdmin';

import { useModuleAdminRights } from '../../../../composables/admin/useModuleAdminRights';

export default defineComponent({
  props: {
      user: {
        type: Object as PropType<any>,
        required: false
      }
  },
  components: {
    ModuleForm
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { userModuleAdminRights, isListLoading, removeUserModuleAdminRights, updateListModuleAdminRights } = useModuleAdminRights({
      userID:props.user._id,
      ...props}, context);

    const { getFeedFromID } = useFeedAdmin(props, context);
    const { getShopContentFromID } = useVigneronOnlineAdmin(props, context);
    const { getSiteFromID } = useSiteAdmin(props, context);
    const { getShopFromID } = useShopAdmin(props, context);

    const { user } = toRefs(props);

    const listItems:Ref<HTMLElement|null> = ref(null);
    const createItemModal:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);
    const updateItemModal:Ref<HTMLElement|null> = ref(null);

    const showNumberOfRows:Ref<boolean> = ref(false);

    const numberOfRows:Ref<number> = computed(() => {
        return userModuleAdminRights.value.length
    })

    const itemToRemove:Ref<any> = ref({});
    const itemToUpdate:Ref<any> = ref({});
    
    const listFields = [
      {
        key: "module",
        label: "Module"
      },
      {
        key: "targetID",
        label: "Target",
        formatter: (value:any, key:any, item:any) => {
          switch(item.module) {
            case "SHOP":
              var shop = getShopFromID(value);
              if(shop) { return shop.name; }
              return value;
              break;
            case "CMS":
              var site = getSiteFromID(value);
              if(site) { return site.name; }
              return value;
              break;
            case "BLOG":
              var feed = getFeedFromID(value);
              if(feed) { return feed.name; }
              return value;
              break;
            case "VIGNERONONLINE":
              var shopContent = getShopContentFromID(value);
              if(shopContent) { return shopContent.shop.name; }
              return value;
              break;
          }
        }
      },
      {
        key: "authRoles",
        label: "Roles"
      },
      {
        key: "options"
      }
    ];

    onMounted(() => {
      
    })

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value;
    })

    const onItemCreated = () => {
      // @ts-ignore
      createItemModal.value.hide();
      updateListModuleAdminRights(props.user._id);
    }

    const onItemUpdated = (item:any) => {
      itemToUpdate.value = item;
      updateListModuleAdminRights(props.user._id);
    }

    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const showUpdateModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.show()
    }

    const showCreateItemModal = () => {
      // @ts-ignore
      createItemModal.value.show()
    }

     const removeItem = () => {
      removeUserModuleAdminRights(itemToRemove.value._id).then((removed:boolean) => {
        // We reset the user to remove
        itemToRemove.value = {};
      })
    }
    

    return {
      user,
      listItems,
      listLoading,
      userModuleAdminRights,
      listFields,
      showNumberOfRows,
      numberOfRows,
      showUpdateModal,
      confirmRemoveItem,
      showCreateItemModal,
      updateItemModal,
      createItemModal,
      removeItemModal,
      itemToUpdate,
      onItemUpdated,
      onItemCreated,
      removeItem
    }
  }
})
</script>