var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{attrs:{"role":"form","name":"module-form"},on:{"submit":_vm.onFormSubmit}},[_c('Select',{attrs:{"label":"Module","placeholder":"Select a module","selectOptions":{
      options: _vm.availableModules
    },"value":_vm.userModuleAdminRightsForm.module,"labelAsColumn":true,"required":true},on:{"update:value":function($event){return _vm.$set(_vm.userModuleAdminRightsForm, "module", $event)}}}),_vm._v(" "),_c('Select',{attrs:{"label":"Target","placeholder":"Select a target","selectOptions":{
      options: _vm.listTargets,
      getOptionLabel : option => option.name,
      reduce: option => option.targetID
    },"value":_vm.userModuleAdminRightsForm.targetID,"labelAsColumn":true,"required":true},on:{"update:value":function($event){return _vm.$set(_vm.userModuleAdminRightsForm, "targetID", $event)}}}),_vm._v(" "),_c('Select',{attrs:{"label":"Roles","placeholder":"Add roles","selectOptions":{
      multiple: true,
      options: _vm.availableTargetRoles
    },"value":_vm.userModuleAdminRightsForm.authRoles,"labelAsColumn":true},on:{"update:value":function($event){return _vm.$set(_vm.userModuleAdminRightsForm, "authRoles", $event)}}}),_vm._v(" "),_c('button',{staticClass:"btn btn-primary ladda-button",attrs:{"data-style":"zoom-in","type":"submit"}},[_vm._v(_vm._s(_vm.buttonLabel))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }