<template>
  
    <form role="form" @submit="onFormSubmit" name="create-role">
        <div class="form-group">
            <label for="createRole" class="sr-only">Role</label>
            <v-select id="createRole" placeholder="Role" v-model="form.role" :options="availableRoles" required></v-select>
        </div>
        <div class="form-group">
            <label for="createModules" class="sr-only">Modules</label>
            <v-select id="createModules" placeholder="Modules" multiple v-model="form.modules" :options="availableModules"></v-select>
        </div>
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Create</button>
    </form>

</template>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: { }
})
export default class Create extends Vue {

  availableModules:string[] = [];
  availableRoles:string[] = [];

  form = {
    role: '',
    modules: []
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  created() {
    // We need to get the list of available modules for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/admin/roles/available-modules', options).then((response:any) => {
      if(response.modules) {  
        this.availableModules = response.modules;
      }
    });
    // We need to get the list of available roles for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/admin/roles/available-roles', options).then((response:any) => {
      if(response.roles) {  
        this.availableRoles = response.roles;
      }
    });
  }

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-role] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "role" : this.form.role,
      "modules" : this.form.modules, // We remove the white space before we split to build the array.
    }

    var options:api.ApiVueOptions =  {
      app: this
    };

    this.laddaSubmit!.start();
    
    api.postAPI('/api/admin/roles/create', input, options).then((response:any) => {
      if(response.created) {  
        this.$emit('role-created', response.role);

        // We reset the field
        this.form.role = "";
        this.form.modules = [];
      }
      this.laddaSubmit!.stop();
    });
  }
  
}
</script>